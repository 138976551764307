import React, { Fragment } from 'react';
import { api, exportData, importData, saiki, submitForm, openModal, checkDateFormat } from '../Module';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ModeView: "Mapel",
            DataPelajaran: [],
            DetailMapel: {},
            DataMurid: [],
            DetailMurid: {},
            DataWali: [],
            DataKelas: [],
            DetailKelas: {},
            DataJurusan: [],
            DataGuru: [],
            DetailPegawai: {},
            q: "",
            ID: "",
            ImportField: [],
            ImportData: []
        };
    }

    async componentDidMount() {
        document.getElementById('loadingSpiner').style.display = "block";
        this.handleMain();
    }

    async handleMain() {
        if (this.state.ModeView == "Mapel") {
            let sql = await api("dataguru", { act: "data pelajaran", q: this.state.q });
            if (sql.status == "sukses") this.setState({ DataPelajaran: sql.data, ID: "", DetailPelajaran: {} });
        } else if (this.state.ModeView == "Siswa") {
            let sql = await api("dataguru", { act: "data murid", q: this.state.q });
            if (sql.status == "sukses") this.setState({ DataMurid: sql.data, ID: "", DetailMurid: {} });
        } else if (this.state.ModeView == "Guru") {
            let sql = await api("dataguru", { act: "data guru", q: this.state.q });
            if (sql.status == "sukses") this.setState({ DataGuru: sql.data, ID: "", DetailPegawai: {} });
        } else if (this.state.ModeView == "Kelas") {
            let sql = await api("dataguru", { act: "data kelas", q: this.state.q });
            if (sql.status == "sukses") this.setState({ DataKelas: sql.data, ID: "", DetailKelas: {} });
        }
        document.getElementById('loadingSpiner').style.display = "none";
    }

    async handleBtnAdd(ID = "") {
        if (this.state.ModeView == "Mapel") {
            let sql = await api("dataguru", { act: "detail data pelajaran", ID: ID });
            this.setState({
                DetailMapel: ID == "" ? {
                    Kode: "",
                    Nama: "",
                    Keterangan: "",
                    Sks: "",
                    Jenis: "Normal",
                    IsWajib: ""
                } : sql.data, ID: ID
            });
            openModal("modalFormMapel");
        } else if (this.state.ModeView == "Siswa") {
            let sql = await api("dataguru", { act: "detail data murid", ID: ID });
            this.setState({
                DataKelas: sql.kelas,
                DataWali: sql.wali,
                DetailMurid: ID == "" ? {
                    NAMA: "",
                    EMAIL: "",
                    NO_HANDPHONE: "",
                    ALAMAT: "",
                    TEMPAT_LAHIR: "",
                    TANGGAL_LAHIR: "",
                    NIK: "",
                    NISN: "",
                    JENIS_KELAMIN: "",
                    AGAMA: "",
                    STATUS_ALAMAT: "",
                    NO_HP_ORANG_TUA: "",
                    JARAK_KE_SEKOLAH: "",
                    TRANSPORTASI_KE_SEKOLAH: "",
                    CITA_CITA: "",
                    HOBBY: "",
                    ASAL_SEKOLAH: "",
                    NAMA_SEKOLAH: "",
                    ALAMAT_SEKOLAH: "",
                    NOMOR_UN: "",
                    STATUS_KELUARGA: "",
                    ANAK_KE: "",
                    JUMLAH_SAUDARA: "",
                    NO_KK: "",
                    NAMA_AYAH: "",
                    TEMPAT_LAHIR_AYAH: "",
                    TANGGAL_LAHIR_AYAH: "",
                    NIK_AYAH: "",
                    PENDIDIKAN_AYAH: "",
                    STATUS_AYAH: "",
                    PEKERJAAN_AYAH: "",
                    PENGHASILAN_AYAH: "",
                    NAMA_IBU: "",
                    TEMPAT_LAHIR_IBU: "",
                    TANGGAL_LAHIR_IBU: "",
                    NIK_IBU: "",
                    PENDIDIKAN_IBU: "",
                    STATUS_IBU: "",
                    PEKERJAAN_IBU: "",
                    PENGHASILAN_IBU: "",
                    ALAMAT_ORANG_TUA: "",
                    JUMLAH_TANGGUNGAN: "",
                    KARTU_JAMINAN: "",
                    NAMA_WALI: "",
                    TEMPAT_LAHIR_WALI: "",
                    TANGGAL_LAHIR_WALI: "",
                    NIK_WALI: "",
                    PENDIDIKAN_WALI: "",
                    PEKERJAAN_WALI: "",
                    PENGHASILAN_WALI: "",
                    ALAMAT_WALI: "",
                    JUMLAH_TANGGUNGAN_WALI: "",
                    PAUD: "",
                } : sql.data, ID: ID
            });
            openModal("modalFormMurid");
        } else if (this.state.ModeView == "Guru") {
            let sql = await api("dataguru", { act: "detail data guru", ID: ID });
            this.setState({
                DetailPegawai: ID == "" ? {
                    NIP: "",
                    NIK: "",
                    NamaDepan: "",
                    NamaBelakang: "",
                    TanggalLahir: saiki(),
                    TempatLahir: saiki(),
                    Alamat: "",
                    Telp: "",
                    Email: "",
                    Pendidikan: "S1",
                    StatusKepegawaian: "Tetap",
                    Status: "1"
                } : sql.data, ID: ID
            });
            openModal("modalFormGuru");
        } else if (this.state.ModeView == "Kelas") {
            let sql = await api("dataguru", { act: "detail data kelas", ID: ID });
            this.setState({
                DetailKelas: ID == "" ? {
                    Kode: "",
                    Nama: "",
                    Level: "",
                    GuruID: "",
                    Keterangan: "",
                    Jurusan: "",
                    Status: ""
                } : sql.data, DataGuru: sql.guru, DataJurusan: sql.jurusan, ID: ID
            });
            openModal("modalFormKelas");
        }
    }

    handleChangeMode(Mode) {
        document.getElementById('loadingSpiner').style.display = "block";
        this.setState({ ModeView: Mode });
        setTimeout(() => {
            this.handleMain();
        }, 500);
    }

    handleModalImport(Jenis) {
        let Field = [];
        if (Jenis == "mapel") {
            Field = [
                { field: "Kode", text: "Kode" },
                { field: "Nama", text: "Nama" },
                { field: "Jenis", text: "Jenis" },
                { field: "Keterangan", text: "Keterangan" },
            ];
        } else if (Jenis == "siswa") {
            Field = [
                { field: "NAMA", text: "NAMA" },
                { field: "EMAIL", text: "EMAIL" },
                { field: "NO_HANDPHONE", text: "NO_HANDPHONE" },
                { field: "ALAMAT", text: "ALAMAT" },
                { field: "TEMPAT_LAHIR", text: "TEMPAT_LAHIR" },
                { field: "TANGGAL_LAHIR", text: "TANGGAL_LAHIR" },
                { field: "NIK", text: "NIK" },
                { field: "NISN", text: "NISN" },
                { field: "JENIS_KELAMIN", text: "JENIS_KELAMIN" },
                { field: "AGAMA", text: "AGAMA" },
                { field: "STATUS_ALAMAT", text: "STATUS_ALAMAT" },
                { field: "NO_HP_ORANG_TUA", text: "NO_HP_ORANG_TUA" },
                { field: "JARAK_KE_SEKOLAH", text: "JARAK_KE_SEKOLAH" },
                { field: "TRANSPORTASI_KE_SEKOLAH", text: "TRANSPORTASI_KE_SEKOLAH" },
                { field: "CITA_CITA", text: "CITA_CITA" },
                { field: "HOBBY", text: "HOBBY" },
                { field: "ASAL_SEKOLAH", text: "ASAL_SEKOLAH" },
                { field: "NAMA_SEKOLAH", text: "NAMA_SEKOLAH" },
                { field: "ALAMAT_SEKOLAH", text: "ALAMAT_SEKOLAH" },
                { field: "NOMOR_UN", text: "NOMOR_UN" },
                { field: "STATUS_KELUARGA", text: "STATUS_KELUARGA" },
                { field: "ANAK_KE", text: "ANAK_KE" },
                { field: "JUMLAH_SAUDARA", text: "JUMLAH_SAUDARA" },
                { field: "NO_KK", text: "NO_KK" },
                { field: "NAMA_AYAH", text: "NAMA_AYAH" },
                { field: "TEMPAT_LAHIR_AYAH", text: "TEMPAT_LAHIR_AYAH" },
                { field: "TANGGAL_LAHIR_AYAH", text: "TANGGAL_LAHIR_AYAH" },
                { field: "NIK_AYAH", text: "NIK_AYAH" },
                { field: "PENDIDIKAN_AYAH", text: "PENDIDIKAN_AYAH" },
                { field: "STATUS_AYAH", text: "STATUS_AYAH" },
                { field: "PEKERJAAN_AYAH", text: "PEKERJAAN_AYAH" },
                { field: "PENGHASILAN_AYAH", text: "PENGHASILAN_AYAH" },
                { field: "NAMA_IBU", text: "NAMA_IBU" },
                { field: "TEMPAT_LAHIR_IBU", text: "TEMPAT_LAHIR_IBU" },
                { field: "TANGGAL_LAHIR_IBU", text: "TANGGAL_LAHIR_IBU" },
                { field: "NIK_IBU", text: "NIK_IBU" },
                { field: "PENDIDIKAN_IBU", text: "PENDIDIKAN_IBU" },
                { field: "STATUS_IBU", text: "STATUS_IBU" },
                { field: "PEKERJAAN_IBU", text: "PEKERJAAN_IBU" },
                { field: "PENGHASILAN_IBU", text: "PENGHASILAN_IBU" },
                { field: "ALAMAT_ORANG_TUA", text: "ALAMAT_ORANG_TUA" },
                { field: "JUMLAH_TANGGUNGAN", text: "JUMLAH_TANGGUNGAN" },
                { field: "KARTU_JAMINAN", text: "KARTU_JAMINAN" },
                { field: "NAMA_WALI", text: "NAMA_WALI" },
                { field: "TEMPAT_LAHIR_WALI", text: "TEMPAT_LAHIR_WALI" },
                { field: "TANGGAL_LAHIR_WALI", text: "TANGGAL_LAHIR_WALI" },
                { field: "NIK_WALI", text: "NIK_WALI" },
                { field: "PENDIDIKAN_WALI", text: "PENDIDIKAN_WALI" },
                { field: "PEKERJAAN_WALI", text: "PEKERJAAN_WALI" },
                { field: "PENGHASILAN_WALI", text: "PENGHASILAN_WALI" },
                { field: "ALAMAT_WALI", text: "ALAMAT_WALI" },
                { field: "JUMLAH_TANGGUNGAN_WALI", text: "JUMLAH_TANGGUNGAN_WALI" },
                { field: "PAUD", text: "PAUD" }
            ];
        }
        this.setState({ ImportField: Field });
    }

    handleDownLoadFormatExcel(Jenis) {
        let data = [], title = "";
        if (Jenis == "mapel") {
            data = [
                { Kode: "MIPA01", Nama: "Fisika", Jenis: "Akademis", Keterangan: "" },
                { Kode: "MIPA02", Nama: "Biologi", Jenis: "Akademis", Keterangan: "" },
            ];
            title = "Contoh Format Import Matapelajaran";
        } else if (Jenis == "siswa") {
            data = [{
                NAMA: "Lailatul Nadhifa",
                EMAIL: "nadhifa@gmail.com",
                NO_HANDPHONE: "085704135172",
                ALAMAT: "Wedoro Utara, Rt.2 , Rw02",
                TEMPAT_LAHIR: "Sidoarjo",
                TANGGAL_LAHIR: "17-12-2007",
                NIK: "3515185712070003",
                NISN: "-",
                JENIS_KELAMIN: "Perempuan",
                AGAMA: "Islam",
                STATUS_ALAMAT: "orang tua",
                NO_HP_ORANG_TUA: "085704135172",
                JARAK_KE_SEKOLAH: "5km-6km",
                TRANSPORTASI_KE_SEKOLAH: "motor",
                CITA_CITA: "Menjadi Translator",
                HOBBY: "Mendengarkan lagu",
                ASAL_SEKOLAH: "SMP",
                NAMA_SEKOLAH: "SMP BUANA WARU",
                ALAMAT_SEKOLAH: "Jl. Kolonel Sugiono No.2-A, Belahan, Wedoro, Kec. Waru, Kabupaten Sidoarjo",
                NOMOR_UN: "",
                STATUS_KELUARGA: "anak kandung",
                ANAK_KE: "2",
                JUMLAH_SAUDARA: "2",
                NO_KK: "3515182901092301",
                NAMA_AYAH: "IMAM  SYAFI'I",
                TEMPAT_LAHIR_AYAH: "Sidoarjo",
                TANGGAL_LAHIR_AYAH: "03-01-1974",
                NIK_AYAH: "3515180301740001",
                PENDIDIKAN_AYAH: "sma/ma",
                STATUS_AYAH: "hidup",
                PEKERJAAN_AYAH: "Wiraswasta",
                PENGHASILAN_AYAH: "1-2jt",
                NAMA_IBU: "ISTIKANAH",
                TEMPAT_LAHIR_IBU: "Mojokerto",
                TANGGAL_LAHIR_IBU: "18-08-1975",
                NIK_IBU: "3515185808750003",
                PENDIDIKAN_IBU: "smp/mts",
                STATUS_IBU: "hidup",
                PEKERJAAN_IBU: "Rumah Tangga",
                PENGHASILAN_IBU: "tidak punya",
                ALAMAT_ORANG_TUA: "-",
                JUMLAH_TANGGUNGAN: "-",
                KARTU_JAMINAN: "-",
                NAMA_WALI: "-",
                TEMPAT_LAHIR_WALI: "-",
                TANGGAL_LAHIR_WALI: "-",
                NIK_WALI: "-",
                PENDIDIKAN_WALI: "-",
                PEKERJAAN_WALI: "-",
                PENGHASILAN_WALI: "-",
                ALAMAT_WALI: "-",
                JUMLAH_TANGGUNGAN_WALI: "-",
                PAUD: "Ya"
            }];
            title = "Contoh Format Import Siswa";
        }
        exportData(data, title, this.state.ImportField);
    }

    async handleLoadExcel(e) {
        let Data = [];
        let Jenis = this.state.ModeView;
        if (Jenis == "Mapel") {
            Data = await importData(e.target.files[0]);
        } else if (Jenis == "Siswa") {
            Data = await importData(e.target.files[0]);
        }
        this.setState({ ImportData: Data });
    }

    async handleProsesImport(e) {
        e.target.disabled = true;
        let Jenis = this.state.ModeView;
        let Data = this.state.ImportData;
        if (Jenis == "Mapel") {
            let ct = 0;
            for (let dd of Data) {
                let sql = await api("dataguru", { act: "tambah pelajaran", Kode: dd.Kode, Nama: dd.Nama, Keterangan: dd.Keterangan, Jenis: dd.Jenis });
                Data[ct].Kode = sql.status
                this.setState({ ImportData: Data });
                ct++;
            }
            e.target.disabled = false;
        } else if (Jenis == "Siswa") {
            let ct = 0;
            for (let dd of Data) {
                dd.act = 'tambah murid';
                let sql = await api("dataguru", {
                    act: "tambah murid",
                    NAMA: dd.NAMA,
                    EMAIL: dd.EMAIL,
                    NO_HANDPHONE: dd.NO_HANDPHONE,
                    ALAMAT: dd.ALAMAT,
                    TEMPAT_LAHIR: dd.TEMPAT_LAHIR,
                    TANGGAL_LAHIR: checkDateFormat(dd.TANGGAL_LAHIR),
                    NIK: dd.NIK,
                    NISN: dd.NISN,
                    JENIS_KELAMIN: dd.JENIS_KELAMIN,
                    AGAMA: dd.AGAMA,
                    STATUS_ALAMAT: dd.STATUS_ALAMAT,
                    NO_HP_ORANG_TUA: dd.NO_HP_ORANG_TUA,
                    JARAK_KE_SEKOLAH: dd.JARAK_KE_SEKOLAH,
                    TRANSPORTASI_KE_SEKOLAH: dd.TRANSPORTASI_KE_SEKOLAH,
                    CITA_CITA: dd.CITA_CITA,
                    HOBBY: dd.HOBBY,
                    ASAL_SEKOLAH: dd.ASAL_SEKOLAH,
                    NAMA_SEKOLAH: dd.NAMA_SEKOLAH,
                    ALAMAT_SEKOLAH: dd.ALAMAT_SEKOLAH,
                    NOMOR_UN: dd.NOMOR_UN,
                    STATUS_KELUARGA: dd.STATUS_KELUARGA,
                    ANAK_KE: dd.ANAK_KE,
                    JUMLAH_SAUDARA: dd.JUMLAH_SAUDARA,
                    NO_KK: dd.NO_KK,
                    NAMA_AYAH: dd.NAMA_AYAH,
                    TEMPAT_LAHIR_AYAH: dd.TEMPAT_LAHIR_AYAH,
                    TANGGAL_LAHIR_AYAH: checkDateFormat(dd.TANGGAL_LAHIR_AYAH),
                    NIK_AYAH: dd.NIK_AYAH,
                    PENDIDIKAN_AYAH: dd.PENDIDIKAN_AYAH,
                    STATUS_AYAH: dd.STATUS_AYAH,
                    PEKERJAAN_AYAH: dd.PEKERJAAN_AYAH,
                    PENGHASILAN_AYAH: dd.PENGHASILAN_AYAH,
                    NAMA_IBU: dd.NAMA_IBU,
                    TEMPAT_LAHIR_IBU: dd.TEMPAT_LAHIR_IBU,
                    TANGGAL_LAHIR_IBU: checkDateFormat(dd.TANGGAL_LAHIR_IBU),
                    NIK_IBU: dd.NIK_IBU,
                    PENDIDIKAN_IBU: dd.PENDIDIKAN_IBU,
                    STATUS_IBU: dd.STATUS_IBU,
                    PEKERJAAN_IBU: dd.PEKERJAAN_IBU,
                    PENGHASILAN_IBU: dd.PENGHASILAN_IBU,
                    ALAMAT_ORANG_TUA: dd.ALAMAT_ORANG_TUA,
                    JUMLAH_TANGGUNGAN: dd.JUMLAH_TANGGUNGAN,
                    KARTU_JAMINAN: dd.KARTU_JAMINAN,
                    NAMA_WALI: dd.NAMA_WALI,
                    TEMPAT_LAHIR_WALI: dd.TEMPAT_LAHIR_WALI,
                    TANGGAL_LAHIR_WALI: checkDateFormat(dd.TANGGAL_LAHIR_WALI),
                    NIK_WALI: dd.NIK_WALI,
                    PENDIDIKAN_WALI: dd.PENDIDIKAN_WALI,
                    PEKERJAAN_WALI: dd.PEKERJAAN_WALI,
                    PENGHASILAN_WALI: dd.PENGHASILAN_WALI,
                    ALAMAT_WALI: dd.ALAMAT_WALI,
                    JUMLAH_TANGGUNGAN_WALI: dd.JUMLAH_TANGGUNGAN_WALI,
                    PAUD: dd.PAUD,
                });
                Data[ct].NAMA = sql.status
                this.setState({ ImportData: Data });
                ct++;
            }
            e.target.disabled = false;
        }
    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <h5 style={{ textAlign: "center" }}>Academy</h5>
                    <ul className="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "Mapel" ? "nav-link active" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => this.handleChangeMode("Mapel")}>Mapel</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "Siswa" ? "nav-link active" : "nav-link"} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => this.handleChangeMode("Siswa")}>Siswa</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "Guru" ? "nav-link active" : "nav-link"} id="pills-guru-tab" data-bs-toggle="pill" data-bs-target="#pills-guru" type="button" role="tab" aria-controls="pills-guru" aria-selected="false" onClick={() => this.handleChangeMode("Guru")}>Guru</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "Kelas" ? "nav-link active" : "nav-link"} id="pills-kelas-tab" data-bs-toggle="pill" data-bs-target="#pills-kelas" type="button" role="tab" aria-controls="pills-kelas" aria-selected="false" onClick={() => this.handleChangeMode("Kelas")}>Kelas</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className={this.state.ModeView == "Mapel" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Kode</th>
                                            <th>Nama</th>
                                            <th>Jenis</th>
                                            <th>Keterangan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataPelajaran.length > 0
                                                ?
                                                this.state.DataPelajaran.map((tr, i) => {
                                                    return (<tr key={i} onClick={() => this.handleBtnAdd(tr.ID)}>
                                                        <td>{tr.Kode}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.Jenis}</td>
                                                        <td>{tr.Keterangan}</td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="btn btn-default" style={{ position: "fixed", bottom: "150px", right: "20px", borderRadius: "50%" }} data-bs-toggle="modal" data-bs-target="#modalImport" onClick={() => this.handleModalImport("mapel")}>
                                <i className='fas fa-upload'></i>
                            </div>
                        </div>
                        <div className={this.state.ModeView == "Siswa" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>NIS</th>
                                            <th>Nama</th>
                                            <th>Kelas</th>
                                            <th>Alamat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataMurid.length > 0
                                                ?
                                                this.state.DataMurid.map((tr, i) => {
                                                    return (<tr key={i} onClick={() => this.handleBtnAdd(tr.ID)}>
                                                        <td>{tr.NIK}</td>
                                                        <td>{tr.NAMA}</td>
                                                        <td>{tr.Kelas}</td>
                                                        <td>{tr.ALAMAT}</td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="btn btn-default" style={{ position: "fixed", bottom: "150px", right: "20px", borderRadius: "50%" }} data-bs-toggle="modal" data-bs-target="#modalImport" onClick={() => this.handleModalImport("siswa")}>
                                <i className='fas fa-upload'></i>
                            </div>
                        </div>
                        <div className={this.state.ModeView == "Guru" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-guru" role="tabpanel" aria-labelledby="pills-guru-tab" tabIndex="0">
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>NIP</th>
                                            <th>Nama</th>
                                            <th>Telp</th>
                                            <th>Alamat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataGuru.length > 0
                                                ?
                                                this.state.DataGuru.map((tr, i) => {
                                                    return (<tr key={i} onClick={() => this.handleBtnAdd(tr.ID)}>
                                                        <td>{tr.NIP}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.Telp}</td>
                                                        <td>{tr.Alamat}</td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={this.state.ModeView == "Kelas" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-kelas" role="tabpanel" aria-labelledby="pills-kelas-tab" tabIndex="0">
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Kode</th>
                                            <th>Nama</th>
                                            <th>Wali Kelas</th>
                                            <th>Keterangan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataKelas.length > 0
                                                ?
                                                this.state.DataKelas.map((tr, i) => {
                                                    return (<tr key={i} onClick={() => this.handleBtnAdd(tr.ID)}>
                                                        <td>{tr.Kode}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.WaliKelas}</td>
                                                        <td>{tr.Keterangan}</td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div style={{ position: "fixed", bottom: "100px", right: "20px" }}>
                        <button className="btn btn-default" style={{ borderRadius: "50%" }} onClick={() => this.handleBtnAdd()}>
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>
                </div>

                <div className="modal fade" id="modalImport" tabIndex="-1" aria-labelledby="modalImport" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Import Data</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm">
                                    <input type="file" className="form-control form-control-sm" id="edtFileExcel" accept=".xls, .xlsx" onChange={(e) => this.handleLoadExcel(e)} />
                                    <button type='button' className='btn btn-sm btn-default' onClick={(e) => this.handleDownLoadFormatExcel('mapel')}>Contoh Format</button>
                                </div>
                                <p></p>
                                <div className="table-responsive">
                                    <table className="table table-stripped table-sm">
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.ImportField.map((th, i) => {
                                                        return <th key={i}>{th.text}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.ImportData.map((tr, i) => {
                                                    return <tr key={i}>
                                                        {
                                                            this.state.ImportField.map((td, ii) => {
                                                                return <td key={ii}>{tr[td.field]}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-default" onClick={(e) => this.handleProsesImport(e, "mapel")}><i className='fas fa-save'></i> Proses</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFormMapel" tabIndex="-1" aria-labelledby="modalMapel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'dataguru', fn: () => this.handleMain(), debug: true })} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Mata Pelajaran" : "Edit Mata Pelajaran"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type='hidden' name="act" value={this.state.ID == "" ? "tambah pelajaran" : "edit pelajaran"} />
                                    <input type='hidden' name="ID" value={this.state.ID} />
                                    <div className='form-group'>
                                        <label>Kode</label>
                                        <input type="text" className='form-control' id="edtKodePelajaran" name="Kode" value={this.state.DetailMapel.Kode} onChange={(e) => {
                                            let data = this.state.DetailMapel;
                                            data.Kode = e.target.value;
                                            this.setState({ DetailMapel: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan kode</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' id="edtNamaPelajaran" name="Nama" value={this.state.DetailMapel.Nama} onChange={(e) => {
                                            let data = this.state.DetailMapel;
                                            data.Nama = e.target.value;
                                            this.setState({ DetailMapel: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan nama</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <input type="text" className='form-control' id="edtKeteranganPelajaran" name="Keterangan" value={this.state.DetailMapel.Keterangan} onChange={(e) => {
                                            let data = this.state.DetailMapel;
                                            data.Keterangan = e.target.value;
                                            this.setState({ DetailMapel: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan keterangan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Jenis</label>
                                        <select className='form-select' id="edtJenis" name="Jenis" value={this.state.DetailMapel.Jenis} onChange={(e) => {
                                            let data = this.state.DetailMapel;
                                            data.Jenis = e.target.value;
                                            this.setState({ DetailMapel: data });
                                        }} >
                                            <option value="Akademis">Akademis</option>
                                            <option value="Non Akademis">Non Akademis</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan jenis</div>
                                    </div>

                                    <div className="form-check form-switch">
                                        <input className="form-check-input" name='IsWajib' type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.DetailMapel.IsWajib == 1 ? true : false} onChange={(e) => {
                                            let data = this.state.DetailMapel;
                                            data.IsWajib = e.target.checked == true ? 1 : 0;
                                            this.setState({ DetailMapel: data });
                                        }} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Wajib</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFormMurid" tabIndex="-1" aria-labelledby="modalMurid" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'dataguru', fn: () => this.handleMain(), debug: true })} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Murid" : "Edit Murid"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type='hidden' name="act" value={this.state.ID == "" ? "tambah murid" : "edit murid"} />
                                    <input type='hidden' name="ID" value={this.state.ID} />
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA" value={this.state.DetailMurid.NAMA} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NAMA = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Email</label>
                                                <input type="email" className='form-control form-control-sm' name="EMAIL" value={this.state.DetailMurid.EMAIL} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.EMAIL = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Email</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Telp</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_HANDPHONE" value={this.state.DetailMurid.NO_HANDPHONE} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NO_HANDPHONE = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_HANDPHONE</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT" value={this.state.DetailMurid.ALAMAT} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.ALAMAT = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR" value={this.state.DetailMurid.TEMPAT_LAHIR} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TEMPAT_LAHIR = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR" value={checkDateFormat(this.state.DetailMurid.TANGGAL_LAHIR)} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TANGGAL_LAHIR = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK" value={this.state.DetailMurid.NIK} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NIK = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NISN</label>
                                                <input type="text" className='form-control form-control-sm' name="NISN" value={this.state.DetailMurid.NISN} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NISN = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NISN</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jenis Kelamin</label>
                                                <input type="text" className='form-control form-control-sm' name="JENIS_KELAMIN" value={this.state.DetailMurid.JENIS_KELAMIN} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.JENIS_KELAMIN = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JENIS_KELAMIN</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Agama</label>
                                                <input type="text" className='form-control form-control-sm' name="AGAMA" value={this.state.DetailMurid.AGAMA} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.AGAMA = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan AGAMA</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Alamat</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_ALAMAT" value={this.state.DetailMurid.STATUS_ALAMAT} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.STATUS_ALAMAT = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_ALAMAT</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tlp. Orang Tua</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_HP_ORANG_TUA" value={this.state.DetailMurid.NO_HP_ORANG_TUA} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NO_HP_ORANG_TUA = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_HP_ORANG_TUA</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jarak Ke Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="JARAK_KE_SEKOLAH" value={this.state.DetailMurid.JARAK_KE_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.JARAK_KE_SEKOLAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JARAK_KE_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Transportasi Ke Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="TRANSPORTASI_KE_SEKOLAH" value={this.state.DetailMurid.TRANSPORTASI_KE_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TRANSPORTASI_KE_SEKOLAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TRANSPORTASI_KE_SEKOLAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Cita-cita</label>
                                                <input type="text" className='form-control form-control-sm' name="CITA_CITA" value={this.state.DetailMurid.CITA_CITA} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.CITA_CITA = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan CITA_CITA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Hobby</label>
                                                <input type="text" className='form-control form-control-sm' name="HOBBY" value={this.state.DetailMurid.HOBBY} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.HOBBY = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan HOBBY</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Asal Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="ASAL_SEKOLAH" value={this.state.DetailMurid.ASAL_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.ASAL_SEKOLAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ASAL_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_SEKOLAH" value={this.state.DetailMurid.NAMA_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NAMA_SEKOLAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_SEKOLAH</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_SEKOLAH" value={this.state.DetailMurid.ALAMAT_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.ALAMAT_SEKOLAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>No. UN</label>
                                                <input type="text" className='form-control form-control-sm' name="NOMOR_UN" value={this.state.DetailMurid.NOMOR_UN} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NOMOR_UN = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NOMOR_UN</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Keluarga</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_KELUARGA" value={this.state.DetailMurid.STATUS_KELUARGA} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.STATUS_KELUARGA = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS KELUARGA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Anak ke</label>
                                                <input type="text" className='form-control form-control-sm' name="ANAK_KE" value={this.state.DetailMurid.ANAK_KE} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.ANAK_KE = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ANAK KE</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jumlah Saudara</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_SAUDARA" value={this.state.DetailMurid.JUMLAH_SAUDARA} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.JUMLAH_SAUDARA = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Jumlah SAUDARA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>No. KK</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_KK" value={this.state.DetailMurid.NO_KK} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NO_KK = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_KK</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_AYAH" value={this.state.DetailMurid.NAMA_AYAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NAMA_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Tgl. Lahir Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_AYAH" value={this.state.DetailMurid.TEMPAT_LAHIR_AYAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TEMPAT_LAHIR_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_AYAH</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Ayah</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_AYAH" value={checkDateFormat(this.state.DetailMurid.TANGGAL_LAHIR_AYAH)} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TANGGAL_LAHIR_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_AYAH" value={this.state.DetailMurid.NIK_AYAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NIK_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_AYAH" value={this.state.DetailMurid.PENDIDIKAN_AYAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PENDIDIKAN_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_AYAH" value={this.state.DetailMurid.STATUS_AYAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.STATUS_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekerjaan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_AYAH" value={this.state.DetailMurid.PEKERJAAN_AYAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PEKERJAAN_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_AYAH" value={this.state.DetailMurid.PENGHASILAN_AYAH} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PENGHASILAN_AYAH = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_IBU" value={this.state.DetailMurid.NAMA_IBU} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NAMA_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_IBU" value={this.state.DetailMurid.TEMPAT_LAHIR_IBU} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TEMPAT_LAHIR_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_IBU</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Ibu</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_IBU" value={checkDateFormat(this.state.DetailMurid.TANGGAL_LAHIR_IBU)} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TANGGAL_LAHIR_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_IBU" value={this.state.DetailMurid.NIK_IBU} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NIK_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_IBU" value={this.state.DetailMurid.PENDIDIKAN_IBU} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PENDIDIKAN_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_IBU" value={this.state.DetailMurid.STATUS_IBU} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.STATUS_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekejraan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_IBU" value={this.state.DetailMurid.PEKERJAAN_IBU} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PEKERJAAN_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_IBU" value={this.state.DetailMurid.PENGHASILAN_IBU} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PENGHASILAN_IBU = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Orang Tua</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_ORANG_TUA" value={this.state.DetailMurid.ALAMAT_ORANG_TUA} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.ALAMAT_ORANG_TUA = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_ORANG_TUA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jumlah Tanggungan</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN" value={this.state.DetailMurid.JUMLAH_TANGGUNGAN} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.JUMLAH_TANGGUNGAN = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JUMLAH_TANGGUNGAN</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Kartu Jaminan</label>
                                                <input type="text" className='form-control form-control-sm' name="KARTU_JAMINAN" value={this.state.DetailMurid.KARTU_JAMINAN} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.KARTU_JAMINAN = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan KARTU_JAMINAN</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_WALI" value={this.state.DetailMurid.NAMA_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NAMA_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_WALI</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_WALI" value={this.state.DetailMurid.TEMPAT_LAHIR_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TEMPAT_LAHIR_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Wali</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_WALI" value={checkDateFormat(this.state.DetailMurid.TANGGAL_LAHIR_WALI)} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.TANGGAL_LAHIR_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_WALI</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_WALI" value={this.state.DetailMurid.NIK_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.NIK_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_WALI" value={this.state.DetailMurid.PENDIDIKAN_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PENDIDIKAN_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekerjaan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_WALI" value={this.state.DetailMurid.PEKERJAAN_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PEKERJAAN_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_WALI" value={this.state.DetailMurid.PENGHASILAN_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PENGHASILAN_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_WALI" value={this.state.DetailMurid.ALAMAT_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.ALAMAT_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jml. Tanggungan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN_WALI" value={this.state.DetailMurid.JUMLAH_TANGGUNGAN_WALI} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.JUMLAH_TANGGUNGAN_WALI = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Kelas</label>
                                                <select className='form-select form-select-sm' name="KelasID" value={this.state.DetailMurid.KelasID} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.KelasID = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} >
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Wali</label>
                                                <select className='form-select form-select-sm' name="WALI_ID" value={this.state.DetailMurid.WALI_ID} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.WALI_ID = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} >
                                                    {
                                                        this.state.DataWali.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>JPAUD</label>
                                                <select className='form-select form-select-sm' name="PAUD" value={this.state.DetailMurid.PAUD} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.PAUD = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} >
                                                    <option value="Ya">Ya</option>
                                                    <option value="Tidak">Tidak</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status</label>
                                                <select className='form-select form-select-sm' name="Status" value={this.state.DetailMurid.Status} onChange={(e) => {
                                                    let data = this.state.DetailMurid;
                                                    data.Status = e.target.value;
                                                    this.setState({ DetailMurid: data });
                                                }} >
                                                    <option value="Aktif">Aktif</option>
                                                    <option value="Lulus">Lulus</option>
                                                    <option value="Pindah">Pindah</option>
                                                    <option value="DO">DO</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFormGuru" tabIndex="-1" aria-labelledby="modalGuru" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'dataguru', fn: () => this.handleMain(), debug: true })} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Guru" : "Edit Guru"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type='hidden' name="act" value={this.state.ID == "" ? "tambah guru" : "edit guru"} />
                                    <input type='hidden' name="ID" value={this.state.ID} />

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>NIP</label>
                                                <input type="text" className='form-control' id="edtNIP" name="NIP" value={this.state.DetailPegawai.NIP} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.NIP = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIP</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>NIK</label>
                                                <input type="text" className='form-control' id="edtNIK" name="NIK" value={this.state.DetailPegawai.NIK} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.NIK = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Depan</label>
                                                <input type="text" className='form-control' name="NamaDepan" value={this.state.DetailPegawai.NamaDepan} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.NamaDepan = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan nama depan</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Belakang</label>
                                                <input type="text" className='form-control' name="NamaBelakang" value={this.state.DetailPegawai.NamaBelakang} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.NamaBelakang = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan nama belakang</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control' name="TempatLahir" value={this.state.DetailPegawai.TempatLahir} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.TempatLahir = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan tempat lahir</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Lahir</label>
                                                <input type="date" className='form-control' name="TanggalLahir" value={this.state.DetailPegawai.TanggalLahir} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.TanggalLahir = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan tanggal lahir</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' name="Alamat" value={this.state.DetailPegawai.Alamat} onChange={(e) => {
                                            let data = this.state.DetailPegawai;
                                            data.Alamat = e.target.value;
                                            this.setState({ DetailPegawai: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan alamat</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Telp</label>
                                        <input type="text" className='form-control' name="Telp" value={this.state.DetailPegawai.Telp} onChange={(e) => {
                                            let data = this.state.DetailPegawai;
                                            data.Telp = e.target.value;
                                            this.setState({ DetailPegawai: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan nomor telepon</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Email</label>
                                        <input type="email" className='form-control' name="Email" value={this.state.DetailPegawai.Email} onChange={(e) => {
                                            let data = this.state.DetailPegawai;
                                            data.Email = e.target.value;
                                            this.setState({ DetailPegawai: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan email</div>
                                    </div>

                                    {this.state.ID == "" &&
                                        <div className='form-group'>
                                            <label>Password</label>
                                            <input type="password" className='form-control' name="Pwd" value={this.state.DetailMurid.Pwd} onChange={(e) => {
                                                let data = this.state.DetailMurid;
                                                data.Pwd = e.target.value;
                                                this.setState({ DetailMurid: data });
                                            }} />
                                            <span className='text-danger'>* Password bawaan adalah tanggal lahir Ex: 05122024</span>
                                        </div>
                                    }

                                    <div className='form-group'>
                                        <label>Pendidikan</label>
                                        <select className='form-select' name="Pendidikan" id="edtPendidikan" value={this.state.DetailPegawai.Pendidikan} onChange={(e) => {
                                            let data = this.state.DetailPegawai;
                                            data.Pendidikan = e.target.value;
                                            this.setState({ DetailPegawai: data });
                                        }} >
                                            <option value="SMA">SMA</option>
                                            <option value="Diploma">Diploma</option>
                                            <option value="Sarjana">Sarjana</option>
                                            <option value="Master">Master</option>
                                            <option value="Doctor">Doctor</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Status Kepegawaian</label>
                                                <select className='form-select' name="StatusKepegawaian" id="edtStatusKepegawaian" value={this.state.DetailPegawai.StatusKepegawaian} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.StatusKepegawaian = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} >
                                                    <option value="Tetap">Tetap</option>
                                                    <option value="Honorer">Honorer</option>
                                                    <option value="Magang">Magang</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan status</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Status</label>
                                                <select className='form-select' name="Status" value={this.state.DetailPegawai.Status} onChange={(e) => {
                                                    let data = this.state.DetailPegawai;
                                                    data.Status = e.target.value;
                                                    this.setState({ DetailPegawai: data });
                                                }} >
                                                    <option value="Aktif">Aktif</option>
                                                    <option value="Keluar">Tidak Aktif</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan status</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFormKelas" tabIndex="-1" aria-labelledby="modalKelas" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'dataguru', fn: () => this.handleMain(), debug: true })} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Kelas" : "Edit Kelas"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type='hidden' name="act" value={this.state.ID == "" ? "tambah kelas" : "edit kelas"} />
                                    <input type='hidden' name="ID" value={this.state.ID} />
                                    <div className='form-group'>
                                        <label>Kode</label>
                                        <input type="text" className='form-control' name="Kode" id="edtKode" value={this.state.DetailKelas.Kode} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Kode = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan kode</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' name="Nama" id="edtNama" value={this.state.DetailKelas.Nama} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Nama = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan nama</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Level</label>
                                        <input type="number" className='form-control' name="Level" id="edtLevel" value={this.state.DetailKelas.Level} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Level = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan level</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>GuruID</label>
                                        <select className='form-select' name="GuruID" id="edtGuruID" value={this.state.DetailKelas.GuruID} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.GuruID = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required >
                                            {
                                                this.state.DataGuru.map((opt, i) => {
                                                    return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan Guru ID</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' name="Keterangan" id="edtKeterangan" value={this.state.DetailKelas.Keterangan} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Keterangan = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan keterangan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Jurusan</label>
                                        <select className='form-select' name="Jurusan" id="edtJurusan" value={this.state.DetailKelas.Jurusan} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Jurusan = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required>
                                            {
                                                this.state.DataJurusan.map((opt, i) => {
                                                    return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan jurusan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.DetailKelas.Status} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Status = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} >
                                            <option value="1">Aktif</option>
                                            <option value="0">Tidak Aktif</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Home;